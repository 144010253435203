import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';

// ================ Action types ================ //

export const FETCH_SEASON_PASS_LIST_REQUEST = 'app/SeasonPassPage/FETCH_SEASON_PASS_LIST_REQUEST';
export const FETCH_SEASON_PASS_LIST_SUCCESS = 'app/SeasonPassPage/FETCH_SEASON_PASS_LIST_SUCCESS';
export const FETCH_SEASON_PASS_LIST_ERROR = 'app/SeasonPassPage/FETCH_SEASON_PASS_LIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  seasonPassList: [],
  seasonPassListInProgress: false,
  seasonPassListError: null,
};

const seasonPassPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SEASON_PASS_LIST_REQUEST:
      return { ...state, seasonPassListInProgress: true, seasonPassListError: null };
    case FETCH_SEASON_PASS_LIST_SUCCESS: {
      return {
        ...state,
        seasonPassListInProgress: false,
        seasonPassList: payload,
      };
    }
    case FETCH_SEASON_PASS_LIST_ERROR:
      return { ...state, seasonPassListInProgress: false, seasonPassListError: payload };

    default:
      return state;
  }
};

export default seasonPassPageReducer;

// ================ Action creators ================ //

export const fetchSeasonPassListRequest = () => ({ type: FETCH_SEASON_PASS_LIST_REQUEST });
export const fetchSeasonPassListSuccess = listings => ({
  type: FETCH_SEASON_PASS_LIST_SUCCESS,
  payload: listings,
});
export const fetchSeasonPassListError = error => ({
  type: FETCH_SEASON_PASS_LIST_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchSeasonPassList = config => (dispatch, getState, sdk) => {
  dispatch(fetchSeasonPassListRequest());
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;
  return sdk.listings
    .query({
      pub_listingType: 'season-pass',
      include: ['author', 'author.profileImage', 'images', 'currentStock'],
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Cropped variants for listing thumbnail images
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        `variants.${variantPrefix}-6x`,

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
    })
    .then(response => {
      response.data.data = response.data.data.map(item => {
        return {
          ...item,
          images: item.relationships.images.data,
          author: item.relationships.author.data,
          currentStock: item.relationships.currentStock.data,
        };
      });

      const entities = denormalisedResponseEntities(response);
      dispatch(fetchSeasonPassListSuccess(entities));
    })
    .catch(e => dispatch(fetchSeasonPassListError(storableError(e))));
};

export const loadData = (params, search, config) => dispatch => {
  return Promise.all([dispatch(fetchSeasonPassList(config))]);
};
